import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import handleError from 'src/store/components/handleError'
import createDataSlice from 'src/store/components/createDataSlice'

const modelName = 'FormComponent'
const pathName = 'form-components'
const tableName = 'formComponentsDropdown'

// ** Fetch Records
export const fetchRecords = createAsyncThunk(
  `${modelName}s/fetch${modelName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(pathName, params)
      return response.data
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Add Record
export const addRecord = createAsyncThunk(`${modelName}s/add${modelName}`, async (data, { getState, dispatch }) => {
  try {
    const response = await axiosInstance.post(`${pathName}`, data)
    return response.data
  } catch (error) {
    handleError(error)
  }
})

// ** Update Record
export const updateRecord = createAsyncThunk(
  `${modelName}s/update${modelName}`,
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${pathName}`, data)
      return response.data
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Delete Record
export const deleteRecord = createAsyncThunk(`${modelName}s/delete${modelName}`, async (id, { getState, dispatch }) => {
  try {
    await axiosInstance.delete(`${pathName}/${id}`)
    dispatch(fetchRecords(getState().appReferrals?.params))
    return id
  } catch (error) {
    handleError(error, 500)
  }
})

export const fetchSpecificRecord = createAsyncThunk(`${modelName}s/fetch${modelName}`, async table_name => {
  try {
    const response = await axiosInstance.get(`${pathName}?table_name=${table_name}`)
    return response.data.data
  } catch (error) {
    handleError(error)
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload?.data) return

  if (payload?.data) {
    console.log(payload.data.length)
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving districts to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** ADD CASES
  // API fetching state
  builder.addCase(addRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(addRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(addRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to add ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** UPDATE CASES
  // API fetching state
  builder.addCase(updateRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(updateRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(updateRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to update ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** DELETE CASES
  // API fetching state
  builder.addCase(deleteRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(deleteRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    state.loading = false // Set loading false to hide loading screen
    state.roles = state.appReferrals?.filter(data => data.id !== action.payload)
  })

  // API response received state (FAILURE)
  builder.addCase(deleteRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to delete ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** FETCH SPECIFIC CASES
  // API fetching state
  builder.addCase(fetchSpecificRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })
  builder.addCase(fetchSpecificRecord.fulfilled, (state, action) => {
    saveToRedux(state, action.payload)
    state.loading = false // Set loading false to hide loading screen
  })
  builder.addCase(fetchSpecificRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(tableName, extraReducers, initialState)
export default dataSlice.reducer
